// Components
import { ProfileDropdown, ProfileDropdownProps } from '../ProfileDropdown';
import { ButtonDropdown } from '../ButtonDropdown';
import { Button } from '../Button';

// Hooks
import { useTranslations } from 'next-intl';

// Icons
import SettingsSvg from 'public/icons/settings.svg';

// Types
export interface GuestUserProps extends ProfileDropdownProps {}
export const GuestUser = ({
  ...props
}: GuestUserProps) => {
  const t = useTranslations('UI.GUEST_USER');
  return <ButtonDropdown ButtonComponent={Button} DropdownComponent={ProfileDropdown} buttonProps={{
    EndAdornment: SettingsSvg
  }} {...props}>
      {t('SITE_MENU')}
    </ButtonDropdown>;
};