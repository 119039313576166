// Styles
import { StyledButtonDropdown, StyledButtonDropdownProps } from './styles';

// Components
import { Dropdown as UIDropdown } from '../Dropdown';
import { Button as UIButton } from '../Button';

// Types
export interface ButtonDropdownProps extends StyledButtonDropdownProps {}

// Component
export const ButtonDropdown = ({
  DropdownComponent = UIDropdown,
  ButtonComponent = UIButton,
  ...props
}: ButtonDropdownProps) => <StyledButtonDropdown {...{
  DropdownComponent,
  ButtonComponent
}} {...props}>
    {props.children}
  </StyledButtonDropdown>;