import React from 'react';

// Styles
import { Wrap, MobileLogo, FullLogo, Left, Right, Center, SubWrap } from './styles';

// Components
import { GuestUser } from '../../components/GuestUser';
export const GuestHeader = () => {
  return <Wrap>
      <SubWrap>
        <Left>
          <FullLogo />
          <MobileLogo />
        </Left>
        <Center />
        <Right>
          <GuestUser />
        </Right>
      </SubWrap>
    </Wrap>;
};