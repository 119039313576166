import { styled, css } from 'styled-components';

// Base HTML tags
import { div, header } from '@ui/base-html-tags';

// Icons
import SHPLogo from 'public/images/shp-logo.svg';
import SHPFullLogoSvg from 'public/images/shp-logo-full.svg';

// Config
import { SHP_LOGO_COLOR } from 'config';

// Styled components
export const MobileLogo = styled(SHPLogo)`
  height: 40px;
  display: none;
  color: ${SHP_LOGO_COLOR};

  ${({
  theme
}) => css`
    ${theme.media.tablet} {
      display: block;
    }
  `};
`;
export const FullLogo = styled(SHPFullLogoSvg)`
  height: 40px;
  color: ${SHP_LOGO_COLOR};

  ${({
  theme
}) => css`
    ${theme.media.tablet} {
      display: none;
    }
  `};
`;
export const Wrap = styled(header)`
  width: 100%;
  height: 66px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  ${({
  theme
}) => css`
    background-color: ${theme.color.white};
    border-bottom: 1px solid ${theme.color.grey100};
  `};
`;
export const SubWrap = styled(div)`
  width: 100%;

  display: grid;
  align-items: center;
  grid-template-areas: 'left center right';
  grid-template-columns: 0fr 0fr 1fr;
  padding: 0 32px;
`;
export const Left = styled(div)`
  grid-area: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const Center = styled(div)`
  grid-area: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Right = styled(div)`
  grid-area: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;