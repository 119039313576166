import React, { useState, useEffect, HtmlHTMLAttributes } from 'react';

// Components
import { Button, ButtonProps } from '@ui/base-button';
import { DropdownMenu, DropdownMenuProps } from '@ui/radix-dropdown';

// Icons
import VerticalDotsIcon from './assets/vertical-dots.svg';
import HorizontalDotsIcon from './assets/horizontal-dots.svg';

// Re export icons
export { VerticalDotsIcon, HorizontalDotsIcon };

// Types
export interface ButtonDropdownSpecificProps {
  DropdownComponent?: React.FC<DropdownMenuProps> | React.FC<any>;
  ButtonComponent?: React.FC<ButtonProps> | React.FC<any>;
  VerticalIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  HorizontalIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  IconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  buttonProps?: Partial<ButtonProps>;
  iconType?: 'vertical' | 'horizontal';
  showIcon?: boolean;
}
export interface ButtonDropdownProps extends ButtonDropdownSpecificProps, DropdownMenuProps {}

// Component
export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  iconType = 'vertical',
  ButtonComponent = Button,
  DropdownComponent = DropdownMenu,
  VerticalIconComponent = VerticalDotsIcon,
  HorizontalIconComponent = HorizontalDotsIcon,
  IconComponent = iconType === 'vertical' ? VerticalIconComponent : HorizontalIconComponent,
  open,
  disabled,
  children,
  className = '',
  onOpenChange,
  showIcon = true,
  buttonProps = {},
  clsPrefix = 'button-radix-dropdown',
  ...props
}) => {
  const [stateOpen, setStateOpen] = useState(open);
  useEffect(() => {
    if (open !== stateOpen) {
      setStateOpen(!!open);
    }
  }, [open]);
  const handleOpenChange = (open: boolean) => {
    setStateOpen(open);
    onOpenChange?.(open);
  };
  return <DropdownComponent asChild onOpenChange={handleOpenChange} open={stateOpen} className={`${clsPrefix} ${className}`} {...{
    disabled
  }} {...props}>
      <ButtonComponent active={stateOpen} className={`${clsPrefix}-button`} {...{
      disabled
    }} {...buttonProps}>
        {children ? children : showIcon && <IconComponent className={`${clsPrefix}-icon`} />}
      </ButtonComponent>
    </DropdownComponent>;
};